import * as React from "react"
import SEO from "../../components/seo";
import LayoutRechtsgebiete from "../../components/LayoutRechtsgebiete";


export default () => {

    return (
        <LayoutRechtsgebiete>
            <div id="content" className="animate-fade-in">
                <h1>Mietrecht</h1>
                <ul>
                    <li>Mietvertrag</li>
                    <li>Kündigungen</li>
                    <li>Räumung</li>
                    <li>Nebenkostenrechnung</li>
                    <li>Wohnungsübergabe</li>
                </ul>
                <p>
                    Neben der Beratung vor Abschluss von Mietverträgen erstelle ich für Sie Mietverträge, sowohl für
                    Wohnraummietverhältnisse als auch für gewerbliche Objekte und informiere Sie bei Wunsch über Ihre
                    Pflichten aber auch Rechte als Vermieter. Die Betreuung bei Kündigung und Räumung sowie die
                    entsprechenden Zwangsvollstreckungsmaßnahmen bilden einen weiteren Schwerpunkt meiner Arbeit.
                </p>
                <p>
                    Aber auch in der Position als Mieter informiere ich Sie gerne über Ihre Rechte (Mietminderung,
                    Überprüfung Nebenkostenabrechnung u.a.)
                </p>
            </div>
        </LayoutRechtsgebiete>
    )
};

export const Head = () => {
    return (<SEO title={'Mietrecht'}/>);
}
